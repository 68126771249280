/*
 * © Createshift Limited 2022
 *
 */

import ClassificationStyles from './classifications.css';

export default class Widget {

    static get key() {

        return 'widget';
    
    }

    static get template() {

        return "<div></div>";

    }

    static get style() {

        return "";

    }

    /**
     * Set up initial properties for widget class instance.
     * @param  {string} key      
     * @param  {object} element  
     * @param  {string} template
     * @param  {string} style            
     */
    constructor(element) {

        this.key = this.constructor.key;

        this.element = element;

        if( this.element === null ) {

            console.warn('ProdPad Warning: Widget not initialized due to missing base element.');

            return;

        }

        this.id = this.element.getAttribute('data-' + this.key);

        if( this.id === null ) {

            console.warn('ProdPad Warning: Widget not initialized due to missing widget ID attribute (data-' + this.key + ') on base element.');

            return;

        }

        this.env = this.getEnv( this.element.getAttribute('data-env') );

        if( this.env === null ) {

            console.warn('ProdPad Warning: Widget not initialized due to missing environment attribute (data-env) on base element.');

            return;

        }

        this.static   = this.element.getAttribute('data-static').toLowerCase() == 'true';
        this.template = this.constructor.template;
        this.style    = this.constructor.style;
        this.config   = {};
        this.data     = {};
        this.common_variables = {};
        this.ui       = {};

        this.initialised = false;

        if(this.static) {

            this.element.addEventListener('updateConfig',(e) => { 

                this.config = Object.assign({},e.detail.config);
                this.data   = e.detail.data || this.data;
                this.common_variables = e.detail.common_variables || this.common_variables;

                // Attach ID to config
                this.config.id = this.id;

                if(this.initialised) {

                    this.removeTemplate();

                    this.removeStyling();

                    this.renderTemplate();

                    this.renderStyling();

                    this.dispatchEvent('updated');

                } else {

                    this.initialise();

                }

            });

        } else {

            this.getConfiguration({
                callback: () => this.initialise(),
                /*  
                    default error callback is handleAPIRequestError, this can be overriden when 
                    we need to use getConfiguration outside of the base widget to allow 
                    incorrect password handling etc. 
                */
                errorCallback: (status, res) => this.handleAPIRequestError(status, res)
            });

        }

        this.dispatchEvent('constructed');

    }

    /**
     * Initialise the widget and run the supplied callback when complete.
     * @param  {Function} callback
     */
    initialise() {

        try {
            this.renderTemplate();

            this.renderStyling();

            setTimeout(() => this.dispatchEvent('initialised'),2);

            this.initialised = true;

        } catch(e) {

            console.warn("ProdPad Warning: Something went wrong when rendering. Please check your widget configuration.", e);

        }

    }

    /**
     * Get the configuration for the widget from the server 
     * and then run the callback function.
     * @param  {Function} callback
     * @param  {Function} errorCallback
     * @param  {String}   method 
     * @param  {String}   endpoint 
     * @param  {Object}   data 
     */
    getConfiguration({ 
        callback, 
        method = 'GET', 
        endpoint = this.getAPIEndpoint(), 
        data = {}, 
        errorCallback 
    }) {
        // Logic to get configuration for widget
        this.req(method, endpoint, data).then(
            (res,httpClient) => {
                this.config = res.config;
                this.data   = res.data;
                this.common_variables = res.common_variables;

                // Attach ID to config
                this.config.id = this.id;

                // Override the header label if it is not set 
                // and we have a default set in client_configuration.js
                if(this.common_variables.classifications.length > 0 && !this.config.classification.header.label) {
                    this.config.classification.header.label = (typeof CLIENT_CONFIGURATION != 'undefined' ? CLIENT_CONFIGURATION.DEFAULT_PRIMARY_CLASSIFICATION : '');
                }

                setTimeout(() => {

                    typeof callback == 'function' && callback();

                },0);

            },
            (res,httpClient) => {

                if(httpClient.status == 403) {
                    
                    console.warn('ProdPad Warning: There is an error when trying to obtain widget configuration.');
                
                } else {

                    console.warn('ProdPad Warning: There was an issue communicating with the server.'); 

                }

                if(typeof errorCallback === 'function') errorCallback(httpClient.status, res);

            }
        ); 

    }

    /**
     * Dispatch an event from the widget.
     * @param  {string} event       
     */
    dispatchEvent(eventType) {

        let event = null;

        switch (eventType) {
            case 'constructed': 
                
                event = document.createEvent('Event');
                event.initEvent("constructed", true, true);
                break;

            case 'initialised': 

                event = document.createEvent('CustomEvent');
                event.initCustomEvent("initialised", true, true, this.config);
                break;

            case 'updated': 
                
                event = document.createEvent('CustomEvent');
                event.initCustomEvent("updated", true, true, this.config);
                break;
        }

        event && this.element.dispatchEvent(event);

    }

    /**
     * Render the template by applying the configuration and rendering the HTML into the DOM.
     */
    renderTemplate() {

        this.element.innerHTML = this.template({
            config: this.config,
            data: this.data,
            common_variables: this.common_variables
        });

    }

    /**
     * Remove the template HTML from the DOM.
     */
    removeTemplate() {

        this.element.innerHTML = '';

    }
    
    /**
     * Render the styling into the head of the DOM.
     */
    renderStyling() {

        if(document.querySelectorAll('style#' + this.key + "-" + this.id).length > 0) return;

        let rendered = this.style({
            config: this.config, 
            data: this.data, 
            common_variables: this.common_variables
        });
        
        let style = document.createElement('style');

        style.id = this.key + '-' + this.id;
        
        style.innerHTML = ClassificationStyles + rendered; 
        
        document.getElementsByTagName('head')[0].appendChild(style);

    }

    /**
     * Remove the styling from the DOM.
     */
    removeStyling() {

        let el = document.querySelector('style#' + this.key + '-' + this.id)
        el.parentNode.removeChild(el)

    }

    /**
     * Get the relative environment information.
     * @param  {string} env
     * @return {object|null}
     */
    getEnv(env) {

        let map = {
            'local': {
                API_BASE: "https://api-widget.prodpad.lan"
            },
            'dev': {
                API_BASE: "https://api-widget-staging." + (typeof CLIENT_CONFIGURATION != 'undefined' ? CLIENT_CONFIGURATION.DOMAIN : 'prodpad.com')
            },
            'staging': {
                API_BASE: "https://api-widget-staging." + (typeof CLIENT_CONFIGURATION != 'undefined' ? CLIENT_CONFIGURATION.DOMAIN : 'prodpad.com')
            },
            'preview': {
                API_BASE: "https://api-widget." + (typeof CLIENT_CONFIGURATION != 'undefined' ? CLIENT_CONFIGURATION.DOMAIN : 'prodpad.com')
            },
            'prod': {
                API_BASE: "https://api-widget." + (typeof CLIENT_CONFIGURATION != 'undefined' ? CLIENT_CONFIGURATION.DOMAIN : 'prodpad.com')
            },
            'sie' : {
                API_BASE: "https://api-widget." + (typeof CLIENT_CONFIGURATION != 'undefined' ? CLIENT_CONFIGURATION.DOMAIN : 'singletenancy-sie.prodpad.com')
            }
        };

        return map[env] || null;

    }

    getAPIEndpoint() {

        return this.env.API_BASE + "/widgets/" + this.id;

    }

    show(node) {

        this.addClass(this.element, node.id + '--visible');

    }

    hide(node) {

        this.removeClass(this.element, node.id + '--visible');

    }

    /**
     * Add class to the supplied element.
     * @param {object} node     
     * @param {string} className
     */
    addClass(node, className) {

        if(!node) return;

        let classes = node.className.split(" ");

        if( classes.indexOf(className) == -1 ) {

            classes.push(className);

            node.className = classes.join(" ");

        }

    }

    /**
     * Remove class from the supplied element.
     * @param {object} node     
     * @param {string} className
     */
    removeClass(node, className) {

        if(!node) return;

        let classes = node.className.split(" ");

        if( classes.indexOf(className) != -1 ) {

            let idx = classes.indexOf(className);

            classes.splice(idx,1);

            node.className = classes.join(" ")

            return;

        }

    }

    /**
     * Validate if value exists or not.
     * @param  {object} val 
     * @return {boolean}     
     */
    valueExists(val) {

        return !!val;

    }

    propagateEvent(e,fn) {

        let element = e.target;

        while(element != undefined) {

            if(fn(e,element)) {
                break;
            }

            element = element.parentElement || undefined;

        }

    }

    /**
     * Send an HTTP Request.
     * @param  {string} method 
     * @param  {string} url    
     * @param  {object} reqdata
     * @return {object}        
     */
    req(method,url,reqdata) {

        var isFormData = reqdata instanceof FormData;

        var httpClient = new XMLHttpRequest(), successCallback, failCallback, response;

        httpClient.addEventListener("load", (e) => { 

            try {

                response = JSON.parse(httpClient.responseText);

            } catch(e) {

                response = null;

            }

            let status = httpClient.status.toString().charAt(0);

            if(status != "2") {

                failCallback && failCallback(response,httpClient);

            } else {

                successCallback && successCallback(response,httpClient);

            }

        },false);


        httpClient.addEventListener("error", (e) => { 

            try {

                response = JSON.parse(httpClient.responseText);

            } catch(e) {

                response = null;

            }

            failCallback && failCallback(response,httpClient);            

        }, false);


        httpClient.addEventListener("abort", (e) => { 

            try {

                response = JSON.parse(httpClient.responseText);

            } catch(e) {

                response = null;

            }

            failCallback && failCallback(response,httpClient);

        }, false);

        //

        httpClient.open(method.toUpperCase(), url, true);

        if (!isFormData) {
            httpClient.setRequestHeader('Content-Type', 'application/json');
        }

        httpClient.send( isFormData ? reqdata : JSON.stringify(reqdata) );

        //

        return {
            then: (successFn,failFn) => {

                successFn && (successCallback = successFn);

                failFn && (failCallback = failFn);

            }
        };

    }

    handleAPIRequestError(status, res) {}

}
