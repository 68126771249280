/*
 * © Createshift Limited 2022
 *
 */

import templateFunc from './template.js';
import styleFunc from './style.js';
import Widget from '../core/Widget.js';

export default class CustomerFeedbackInAppWidget extends Widget {

	static get key() {

		return 'pp-cfiw-widget';

	}

	static get template() {

		return templateFunc;

	}

	static get style() {

		return styleFunc;

	}

	constructor(element) {

		super(element);
	
        this.nodes = {};

        this.classificationHasBeenSet = false;
        this.classificationMenuOpen = false;
        this.currentClassificationView = 'primary';
        this.selectedClassifications = [];
    }
    
    renderStyling() {
        this.config = this.filterConfig(this.config);
        super.renderStyling();
    }

	renderTemplate() {

		super.renderTemplate();

		setTimeout(() => { this.bind(); }, 0);

	}

	initialise() {

        super.initialise();

        this.classificationsEnabled = this.common_variables.classifications.length > 0;

		setTimeout(() => { 

			this.initialiseUI();

		}, 0);

	}

	bind() {

	    this.nodes = {
            body: document.body,
            root: this.element,
            main: {
                trigger: this.element.querySelector('#pp-cfpiaw-trigger'),
                overlay: this.element.querySelector('#pp-cfpiaw-overlay'),
                dialog : this.element.querySelector('#pp-cfpiaw-dialog')
            },
            form: {
                root     		: this.element.querySelector('#pp-cfpiaw__form'),
                name     		: this.element.querySelector('#pp-cfpiaw__form-name'),
                name_error		: this.element.querySelector('#pp-cfpiaw__form-name-err'),
                email    		: this.element.querySelector('#pp-cfpiaw__form-email'),
                email_error		: this.element.querySelector('#pp-cfpiaw__form-email-err'),
                feedback 		: this.element.querySelector('#pp-cfpiaw__form-suggestion'),
                feedback_error	: this.element.querySelector('#pp-cfpiaw__form-suggestion-err'),
                btn_1    		: this.element.querySelector('#pp-cfpiaw__form-section-1 .pp-cfpiaw__form-submit'),
                btn_2    		: this.element.querySelector('#pp-cfpiaw__form-section-2 .pp-cfpiaw__form-submit'),
                section_1		: this.element.querySelector('#pp-cfpiaw__form-section-1'),
                section_2		: this.element.querySelector('#pp-cfpiaw__form-section-2'),
                section_3		: this.element.querySelector('#pp-cfpiaw__form-section-3'),
                close 			: this.element.querySelector('.pp-cfpiaw-dialog__close'),
                success         : this.element.querySelector('#pp-cfpiaw-alert-success'),
                error           : this.element.querySelector('#pp-cfpiaw-alert-error'),
                classification_menu: {
                    root: this.element.querySelector('.pp-classification-menu'),
                    inner_menu: this.element.querySelector('.pp-classification-menu__menu'),
                    btn: this.element.querySelector('.pp-classification-menu__btn'),
                    icons_list: this.element.querySelector('.pp-classification-menu__classification-icons-list'),
                    primary_menu: this.element.querySelector('.pp-classification-menu__primary-classifications'),
                    primary_classification_inputs: this.element.querySelectorAll('.pp-classification-menu__primary-classifications input'),
                    secondary_menu: this.element.querySelector('.pp-classification-menu__secondary-classifications'),
                    secondary_menu_back_btn: this.element.querySelector('.pp-classification-menu__secondary-classifications-back-btn'),
                    secondary_menu_primary_icon: this.element.querySelector('.pp-classification-menu__secondary-classifications-primary-icon'),
                }
            },
            privacy: {
                link    : this.element.querySelector('#pp-cfpiaw-privacy-link'),
                dialog  : this.element.querySelector('#pp-cfpiaw-privacy-dialog'),
                close   : this.element.querySelector('#pp-cfpiaw-privacy-dialog-close')
            }
        };

        this.upload = {
            elements: {
                input: this.element.querySelector('#pp-cfpiaw__form-field-wrapper__attachments__input'),
                dropzone: this.element.querySelector('.pp-cfpiaw__form-field-wrapper__attachments__body__dropzone'),
                files: this.element.querySelector('.pp-cfpiaw__form-field-wrapper__attachments__body__files'),
                uploadBtn: this.element.querySelector('.pp-cfpiaw__form-field-wrapper__attachments__label__button'),
            },
            templates: {
                file: this.element.querySelector('#pp-cfpiaw__template__file'),
                error: this.element.querySelector('#pp-cfpiaw__template__error')
            },
            data: {
                files: [],
                maxFiles: 5,
                maxFileSize: 8,
            },
            events: {
                handleUploadDropzoneDragEnter: this.handleUploadDropzoneDragEnter.bind(this),
                handleUploadDropzoneDragOver: this.handleUploadDropzoneDragOver.bind(this),
                handleUploadDropzoneDragLeave: this.handleUploadDropzoneDragLeave.bind(this),
                handleUploadDropzoneDrop: this.handleUploadDropzoneDrop.bind(this),
                handleUploadSelectFiles: this.handleUploadSelectFiles.bind(this),
            },
            isValid: () => {
                const validFiles = this.upload.data.files.length <= this.upload.data.maxFiles;
                const validSize = this.upload.data.files.every(f => (f.size / 1024 / 1024) <= this.upload.data.maxFileSize);
                const validExtensions = this.upload.data.files.every(f => {
                    const fileExtension = f.name.split('.').pop();
                    return ['jpg', 'png', 'pdf', 'txt'].includes(fileExtension);
                });
                return validFiles && validSize && validExtensions;
            }
        };

    }
	
	initialiseUI() {

		this.handlerBodyKeyup  = this.handlerBodyKeyup.bind(this);
		this.handlerFormInput  = this.handlerFormInput.bind(this);
		this.handlerFormClick  = this.handlerFormClick.bind(this);
		this.handlerFormBlur   = this.handlerFormBlur.bind(this);
        this.handlerFormSubmit = this.handlerFormSubmit.bind(this);

        this.nodes.root.addEventListener('click', (e) => {

			this.propagateEvent(e,(e,target) => {

				if(target == this.nodes.main.trigger) {

                    this.showDialog(); 

                    return true;

                }

                if(target == this.nodes.main.overlay) {

                    this.closeDialog();

                    return true;

                }

                if(target == this.nodes.form.close) {

                    this.closeDialog();

                    return true;

                }

			});

    	});

        this.nodes.root.addEventListener('openDialog',(e) => { 

            this.showDialog();

            // sections
            this.show(this.nodes.form.section_1);
            this.hide(this.nodes.form.section_2);

        });

        this.nodes.root.addEventListener('closeDialog',(e) => { 

            this.closeDialog();

        });

        this.nodes.root.addEventListener('showSection',(e) => { 

            switch (e.detail) {
                case "1":
                    this.show(this.nodes.form.section_1);
                    this.hide(this.nodes.form.section_2);
                    break;
                case "2":
                    this.hide(this.nodes.form.section_1);
                    this.show(this.nodes.form.section_2);
                    break;
            }

        });

        this.nodes.root.addEventListener('setNameValue',(e) => { 

            this.nodes.form.name.value = e.detail;

        });

        this.nodes.root.addEventListener('setEmailValue',(e) => { 

            this.nodes.form.email.value = e.detail;

        });

        // classifications are enabled
        if(this.classificationsEnabled) {
            this.nodes.form.classification_menu.secondary_classification_bg = 
                this.element.querySelector('.secondary-classification-bg').cloneNode({deep: true});

            this.nodes.main.dialog.classList.add('has-classification');

            this.nodes.form.classification_menu.root.addEventListener('click', () => {
                if(!this.classificationMenuOpen) this.openClassificationMenu();
            });

            this.nodes.form.feedback.addEventListener('focus', () => {
                if(!this.classificationHasBeenSet) {
                    this.openClassificationMenu();
                }
            });
    
            this.nodes.form.feedback.addEventListener('blur', () => {
                // re-require classification selection each time the input is focused
                this.classificationHasBeenSet = false;
            });
    
            // Prevent text entry until a classification has been selected
            this.nodes.form.feedback.addEventListener('keypress', e => {
                if(!this.classificationHasBeenSet) e.preventDefault();
            });

            // enable mene button once a classification has been set 
            this.nodes.form.classification_menu.primary_classification_inputs.forEach(input => {
                input.addEventListener('change', () => {
                    this.nodes.form.classification_menu.btn.removeAttribute('disabled');
                });
            });
    
            this.nodes.form.classification_menu.btn.addEventListener('mousedown', e => {
                e.preventDefault();
                this.handleClassificationsMenuBtn();
            });
    
            this.nodes.form.classification_menu.btn.addEventListener('keypress', e => {
                e.preventDefault();
                this.handleClassificationsMenuBtn();
            });
    
            this.nodes.form.classification_menu.secondary_menu_back_btn
                .addEventListener('click', this.resetClassificationMenuState.bind(this));
    
            // Hide classication menu if click outside is detected
            document.addEventListener('mousedown', this.detectClickOutsideClassificationMenu.bind(this));
        }

        // Uploading
        this.upload.elements.input.addEventListener('change', this.upload.events.handleUploadSelectFiles);
        this.upload.elements.dropzone.addEventListener('dragenter', this.upload.events.handleUploadDropzoneDragEnter, false);
        this.upload.elements.dropzone.addEventListener('dragover', this.upload.events.handleUploadDropzoneDragOver, false);
        this.upload.elements.dropzone.addEventListener('dragleave', this.upload.events.handleUploadDropzoneDragLeave, false);
        this.upload.elements.dropzone.addEventListener('drop', this.upload.events.handleUploadDropzoneDrop, false);
        this.upload.elements.uploadBtn.addEventListener('click', () => this.upload.elements.input.click());

    }

    toggleEmptyClass(input) {

        if( input && input.value ) {

            this.removeClass(input,'empty');

        } else {

            this.addClass(input,'empty');

        }

    }

    toggleValidation() {

        if( !this.valueExists(this.nodes.form.name.value) ) {

            this.show(this.nodes.form.name_error);

        } else {

            this.hide(this.nodes.form.name_error);

        }

        if( !this.valueExists(this.nodes.form.feedback.value) ) {

            this.show(this.nodes.form.feedback_error);

        } else {

            this.hide(this.nodes.form.feedback_error);

        }

    }

    resetValidation() {

        this.hide(this.nodes.form.name_error);

        this.hide(this.nodes.form.feedback_error);

    }
         
    showDialog() {

        this.show(this.nodes.main.dialog);

        this.toggleEmptyClass(this.nodes.form.name);
        this.toggleEmptyClass(this.nodes.form.email);
        
        this.formReset();

        this.addClass(this.nodes.body, 'pp-cfiaw-dialog--visible');

        // Auto-focus on feedback input if live
        if(this.env === 'prod') {

            setTimeout(() => {

                this.nodes.form.feedback.focus();

            },50);

        }

        // add event listeners
        document.body.addEventListener('keyup', this.handlerBodyKeyup);

        this.nodes.root.addEventListener('input', this.handlerFormInput);

        this.nodes.root.addEventListener('onchange', this.handlerFormInput);

        this.nodes.root.addEventListener('click', this.handlerFormClick);

        this.nodes.root.addEventListener('focusout', this.handlerFormBlur);

        this.nodes.root.addEventListener('submit', this.handlerFormSubmit);

        if(this.classificationsEnabled) {
            this.nodes.form.classification_menu.root.classList.add('pp-classification-menu--unclassified');
        }
    }
	            
    closeDialog() {

        this.hide(this.nodes.main.dialog);

        this.removeClass(this.nodes.body, 'pp-cfiaw-dialog--visible');

        // remove event listeneres
        document.body.removeEventListener('keyup', this.handlerBodyKeyup);

        this.nodes.root.removeEventListener('input', this.handlerFormInput);

        this.nodes.root.removeEventListener('onchange', this.handlerFormInput);

        this.nodes.root.removeEventListener('click', this.handlerFormClick);

        this.nodes.root.removeEventListener('focusout', this.handlerFormBlur);

        this.nodes.root.removeEventListener('submit', this.handlerFormSubmit);

        // reset form
        this.formReset();

        if(this.classificationsEnabled) {
            this.closeClassificationMenu();
            this.nodes.form.classification_menu.root.classList.remove('pp-classification-menu--unclassified');
            this.nodes.form.classification_menu.btn.setAttribute('disabled', 'disabled');
        }
    }

    showPrivacyDialog() {

        this.show(this.nodes.privacy.dialog);

    }

    closePrivacyDialog() {

        this.hide(this.nodes.privacy.dialog);

    }

    handleUploadDropzoneDragEnter(e) {
        e.preventDefault()
        e.stopPropagation()
        this.upload.elements.dropzone.classList.add('pp-cfpiaw__form-field-wrapper__attachments__body__dropzone--active');
    }

    handleUploadDropzoneDragOver(e) {
        e.preventDefault()
        e.stopPropagation()
        this.upload.elements.dropzone.classList.add('pp-cfpiaw__form-field-wrapper__attachments__body__dropzone--active');
    }

    handleUploadDropzoneDragLeave(e) {
        e.preventDefault()
        e.stopPropagation()
        this.upload.elements.dropzone.classList.remove('pp-cfpiaw__form-field-wrapper__attachments__body__dropzone--active');
    }

    handleUploadDropzoneDrop(e) {
        e.preventDefault()
        e.stopPropagation()
        this.upload.elements.dropzone.classList.remove('pp-cfpiaw__form-field-wrapper__attachments__body__dropzone--active');
        const dt = e.dataTransfer;
        const files = dt.files;
        this.upload.data.files.push(...files);
        this.renderFilesList();
    }

    handleUploadSelectFiles(e) {
        const files = e.target.files;
        this.upload.data.files.push(...files);
        this.renderFilesList();
    }

    renderFilesList() {

        const createFile = (name, size, index) => {
            const fileClone = this.upload.templates.file.content.cloneNode(true);
            const cloneRoot = fileClone.querySelector('.pp-cfpiaw__template__file');
            const cloneName = cloneRoot.querySelector('.pp-cfpiaw__template__file__name');
            const cloneSize = cloneRoot.querySelector('.pp-cfpiaw__template__file__size');
            cloneRoot.dataset.index = index;
            cloneRoot.dataset.name = name;
            cloneName.innerHTML = name;
            cloneSize.innerHTML = `(${size.toFixed(2)} MB)`;
            return cloneRoot;
        }

        const createError = (title, message) => {
            const errorClone = this.upload.templates.error.content.cloneNode(true);
            const errorCloneRoot = errorClone.querySelector('.pp-cfpiaw__template__error');
            const errorCloneTitle = errorCloneRoot.querySelector('.pp-cfpiaw__template__error__title');
            const errorCloneMessage = errorCloneRoot.querySelector('.pp-cfpiaw__template__error__message');
            errorCloneTitle.innerHTML = title;
            errorCloneMessage.innerHTML = message;
            return errorCloneRoot;
        }

        // Reset file list
        this.upload.elements.files.innerHTML = '';

        // Render files
        this.upload.data.files.forEach((file, index) => {

            // File meta
            const fileName = file.name;
            const fileSizeAsMB = (file.size / 1024 / 1024);
            const fileExtension = fileName.split('.').pop();

            // Clone template
            const fileClone = createFile(fileName, fileSizeAsMB, index);

            // Attach event listener to remove self
            fileClone.querySelector('.pp-cfpiaw__template__file__delete').onclick = (e) => {
                this.upload.data.files.splice(fileClone.dataset.index, 1);
                this.renderFilesList(this.upload.elements.files);
            }

            // Append to DOM
            this.upload.elements.files.appendChild(fileClone);

            // Handle file errors
            let error = false;
            let errorMessage = '';
            let errorTitle = '';

            // File is too large
            if (fileSizeAsMB > this.upload.data.maxFileSize) {
                error = true;
                errorTitle = 'This file is too big.';
                errorMessage = 'Maximum file size is 8 MB. Please choose another file or remove it and try again.';
            }

            // Unsupported file type
            const allowedExtensionTypes = ['jpg', 'png', 'pdf', 'txt'];
            if (!allowedExtensionTypes.includes(fileExtension)) {
                error = true;
                errorTitle = 'This file type is not supported.';
                errorMessage = 'Please choose a file with a .jpg, .png, .pdf, or .txt extension.';
            }

            // If there's an error, append error message
            if (error) {

                // Append error class to file
                fileClone.classList.add('pp-cfpiaw__template__file--error');

                // Create error
                const errorClone = createError(errorTitle, errorMessage);
                this.upload.elements.files.appendChild(errorClone);
            }

        })

        // If more than 5 files, show error
        if (this.upload.data.files.length > this.upload.data.maxFiles) {
            const errorClone = createError('You\'ve exceeded our maximum number of attachments.', 'You can only attach up to 5 files at a time. Please remove and try again.');
            this.upload.elements.files.appendChild(errorClone);

            // Give list disabled state
            this.upload.elements.dropzone.classList.add('pp-cfpiaw__form-field-wrapper__attachments__body__dropzone--disabled');
        } else {
            this.upload.elements.dropzone.classList.remove('pp-cfpiaw__form-field-wrapper__attachments__body__dropzone--disabled');
        }

        if (!this.dialogOpen) {
            this.nodes.form.btn_1.disabled = !this.nodes.form.feedback.value || !this.upload.isValid();
        }

    }

    resetFilesList() {
        this.upload.data.files = [];
        this.renderFilesList();
    }

    handlerBodyKeyup(e) {

        if( e.keyCode == 27 ) {

            this.closeDialog();

            this.closePrivacyDialog();

        }

    }

    handlerFormSubmit(e) {

        e.preventDefault();

    }
	            
    handlerFormInput(e) {

		this.propagateEvent(e,(e,element) => {

            if( 
                element == this.nodes.form.name     || 
                element == this.nodes.form.email    || 
                element == this.nodes.form.feedback
            ) {

                if (this.dialogOpen) {
                    this.nodes.form.btn_1.disabled = !this.nodes.form.feedback.value;
                } else {
                    this.nodes.form.btn_1.disabled = !this.nodes.form.feedback.value || !this.upload.isValid();
                }
                this.nodes.form.btn_2.disabled = !(this.nodes.form.feedback.value && this.nodes.form.name.value);

                // name validator
                if( element == this.nodes.form.name ||
                    element == this.nodes.form.feedback ) {

                    this.toggleValidation();

                }

                this.toggleEmptyClass(element);

                return true;

            }

        });

        return;

    }
	           
    handlerFormBlur(e) {

		this.propagateEvent(e,(e,element) => {

            if( element == this.nodes.form.name ||
                element == this.nodes.form.feedback ) {

                this.toggleValidation();

                return true;

            }

        });

        return;

    }
	            
    handlerFormClick(e) {   

    	this.propagateEvent(e,(e,element) => {

            if( element == this.nodes.form.btn_1 && this.valueExists(this.nodes.form.feedback.value) ) {

                this.formNext();

                return true;

            }

            if( element == this.nodes.form.btn_2 && this.valueExists(this.nodes.form.name.value) ) {

            	this.formSubmit(e);

            	return true;

        	}

            if(element == this.nodes.privacy.link) {

                this.showPrivacyDialog();

                return true;

            }

            if(element == this.nodes.privacy.close) {

                this.closePrivacyDialog();

                return true;

            }
        });

    }
	            
    formReset() {

        /* input reset */
        this.nodes.form.feedback.value = '';

        this.toggleEmptyClass(this.nodes.form.feedback);

        this.resetFilesList();

        /* input validation */
        this.nodes.form.btn_1.disabled = true;
        this.nodes.form.btn_2.disabled = true;

        this.hide(this.nodes.form.name_error);
        this.hide(this.nodes.form.feedback_error);

        // sections
        this.show(this.nodes.form.section_1);
        this.hide(this.nodes.form.section_2);

        this.selectedClassifications = [];

    }

    formNext() {

        this.resetValidation();

        this.hide(this.nodes.form.section_1);
        this.show(this.nodes.form.section_2);

        setTimeout(() => {

            this.nodes.form.name.focus();

        },50);

    }

    formSubmit(e) {
        e.preventDefault();

        const data = new FormData();
        data.append('name', this.nodes.form.name.value);
        data.append('email', this.nodes.form.email.value);
        data.append('feedback', this.nodes.form.feedback.value);

        this.upload.data.files.forEach((file) => {
            data.append('files[]', file);
        })

        if(this.classificationsEnabled) {
            data.append('classification_ids', this.selectedClassifications.map(classification => classification.classification_id));
        } 
        
        this.addClass(this.nodes.form.root,'pp-cfpiaw__form--loading');

        this.req('POST', this.getAPIEndpoint(), data).then((res) => {

            this.removeClass(this.nodes.form.root,'pp-cfpiaw__form--loading');

            this.show(this.nodes.form.success);

            setTimeout(() => this.hide(this.nodes.form.success), 3000);

            this.closeDialog();

        },(respo) => {

        	this.show(this.nodes.form.error);

            setTimeout(() => this.hide(this.nodes.form.error), 3000);

            this.closeDialog();

        });

    }
    
    filterConfig(config) {
        let filtered = config;

        if(filtered.styles.primary_colour && filtered.styles.primary_colour.charAt(0) != '#') filtered.styles.primary_colour = '#' + filtered.styles.primary_colour;
        if(filtered.styles.icon_color && filtered.styles.icon_color.charAt(0) != '#') filtered.styles.icon_color = '#' + filtered.styles.icon_color;

        return filtered;
    }

    openClassificationMenu() {
        this.nodes.form.classification_menu.root.classList.add('pp-classification-menu--open');
        // on open, focus on first primary classification (beneficial for keyboard nav)
        this.nodes.form.classification_menu.inner_menu.querySelectorAll('input')[0].focus();
        this.classificationMenuOpen = true; 
    }

    closeClassificationMenu() {
        this.nodes.form.classification_menu.root.classList.remove('pp-classification-menu--open');
        this.classificationMenuOpen = false;
    }

    // on reset, revert to initial display state
    resetClassificationMenuState() {
        this.nodes.form.classification_menu.inner_menu
            .classList.remove('pp-classification-menu__menu--view-secondary');
        this.currentClassificationView = 'primary';
        this.nodes.form.classification_menu.btn.innerHTML = 'Next';
        this.nodes.form.classification_menu.secondary_menu_primary_icon.innerHTML = '';
    }

    confirmClassifications() { 
        // get all checked checkboxes / radio buttons and return the relevant classification ids 
        this.selectedClassifications = this.resolveClassificationsFromIDs(
            [...this.nodes.form.classification_menu.root.querySelectorAll('input:checked')]
            .map(c => c.id)
        )
        this.classificationHasBeenSet = true;
        // shift focus back to text input
        this.nodes.form.feedback.focus();
        // input is now classified, remove default eye icon and render confirmed classifcations list
        this.nodes.form.classification_menu.root.classList.remove('pp-classification-menu--unclassified');
        // append selected classifications to the DOM
        this.renderSelectedClassifications();
        // reset classification menu view state and collapse
        this.resetClassificationMenuState();
        this.closeClassificationMenu();
    }

    detectClickOutsideClassificationMenu(e) {
        const isClickInsideMenu = this.nodes.form.classification_menu.root.contains(e.target);
        if(this.classificationMenuOpen && !isClickInsideMenu) {
            this.resetClassificationMenuState();
            this.closeClassificationMenu();
        }
    }

    handleClassificationsMenuBtn() {
        const hasSecondaryClassifications = !!this.common_variables.classifications.filter(c => c.type === 'secondary');
        // "Next" button has been hit
        if(hasSecondaryClassifications && this.currentClassificationView !== 'secondary') {
            this.switchClassificationsMenuToSecondary();
        } else {
            this.confirmClassifications();
        }
    }

    switchClassificationsMenuToSecondary() {
        // display secnodary classifications
        this.nodes.form.classification_menu.inner_menu.classList.add('pp-classification-menu__menu--view-secondary');
        this.currentClassificationView = 'secondary';
        this.nodes.form.classification_menu.btn.innerHTML = 'Save';
        // display selected primary on secondary meny
        this.addPrimaryClassificationIconToSecondaryMenu();
        // shift document focus to first secondary classification
        document.querySelectorAll('.pp-classification-menu__secondary-classifications input')[0].focus();
    }

    addPrimaryClassificationIconToSecondaryMenu() {
        const selectedPrimary = 
            [...this.nodes.form.classification_menu.primary_menu
            .querySelectorAll('input:checked')]
            .map(c => c.id)[0];

        const selectedPrimaryData = 
            this.common_variables.classifications
            .filter(c => c.classification_id === selectedPrimary)[0];
            
        const primaryIcon = this.createClassificationIcon(selectedPrimaryData);
        this.nodes.form.classification_menu.secondary_menu_primary_icon.appendChild(primaryIcon);
    }

    resolveClassificationsFromIDs(IDs) {
        return IDs.map(id => {
            return this.common_variables.classifications.find(c => {
                if(c.classification_id === id) return c;
            })
        });
    }

    // create classification icon DOM element
    createClassificationIcon(classification) {
        const { colour, type, acronym } = classification;
        const icon = document.createElement('div');
        icon.classList = `
            pp-classification-c--${colour}
            pp-classification-icon 
            pp-classification-icon--type-${type}
        `.trim();

        const iconAcronym = document.createElement('div');
        iconAcronym.classList.add('pp-classification-icon__acronym');
        iconAcronym.innerHTML = acronym;

        icon.appendChild(iconAcronym);

        // clone svg from in-menu secondary classifications, add it to newly created display classifications
        type === 'secondary' && icon.appendChild(this.nodes.form.classification_menu.secondary_classification_bg);

        return icon;
    }


    renderSelectedClassifications() {
        // clear any previous classifications
        this.nodes.form.classification_menu.icons_list.innerHTML = '';
        // add newly selected classifications to DOM
        for(const classification of this.selectedClassifications) {
            const icon = this.createClassificationIcon(classification);
            this.nodes.form.classification_menu.icons_list.appendChild(icon);
        }
    }
}
