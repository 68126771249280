/*
 * © Createshift Limited 2022
 *
 */

import CustomerFeedbackInAppWidget from './CustomerFeedbackInAppWidget.js';

(function(){

	let elements = document.querySelectorAll('[data-' + CustomerFeedbackInAppWidget.key + ']');

	for (var i = 0; i < elements.length; i++) {

		if(elements[i].hasAttribute('data-bound') && elements[i].getAttribute('data-bound') == 'true') continue;
	
		let widget = new CustomerFeedbackInAppWidget(elements[i]);

		elements[i].setAttribute('data-bound','true');

	}

})();
